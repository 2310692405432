import PageLoader from 'core/components/PageLoader';
import RootLayout from 'core/layout/RootLayout';
import { RoutePaths } from 'core/models';
import NotFound from 'core/pages/NotFound';
import Callback from 'feature/auth/pages/Callback';
import Login from 'feature/auth/pages/Login';
import TokenInfo from 'feature/auth/pages/TokenInfo';
import { ComponentType, ReactNode, Suspense, lazy } from 'react';
import { Loader } from 'hudskit-framework-react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import * as S from '../feature/dashboard/pages/Dashboard/styled';

export enum UserRoutes {
  UserLoginCallback = 'callback',
}

const Assets = lazy(() => import('feature/assets/pages/Assets'));
const AssetDetails = lazy(() => import ('feature/assets/components/AssetDetails'));
const AssetDetailsProductInfo = lazy(() => import ('feature/assets/components/AssetDetailsProductInfo'));
const AssetDetailsContact = lazy(() => import ('feature/assets/components/AssetDetailsContact'));
const AssetDetailsServiceContract = lazy(() => import ('feature/assets/components/AssetDetailsServiceContract'));
const AssetsMap = lazy(() => import('feature/assets/pages/AssetsMap'));
const Dashboard = lazy(() => import('feature/dashboard/pages/Dashboard'));
const Companies = lazy(() => import('feature/companies/pages/Companies'));
const CompaniesDetails = lazy(() => import('feature/companies/components/CompaniesDetails'));
const Faq = lazy(() => import('feature/faq/pages/Faq'));

const componentWithSuspenseAndErrorBoundary = (Component: ComponentType, fallback: ReactNode = (
  <S.LoaderDiv>
    {Component !== Dashboard && <Loader size="medium" center />}
  </S.LoaderDiv>
)) => (
  <Suspense fallback={fallback}>
    <ErrorBoundary>
      <Component />
    </ErrorBoundary>
  </Suspense>
);

const getRoutes = () : RouteObject[] => [
  {
    path: '/',
    element: <RootLayout />,
    id: 'root',
    children: [
      {
        path: RoutePaths.Dashboard,
        element: componentWithSuspenseAndErrorBoundary(Dashboard),
      },
      {
        path: RoutePaths.Assets,
        element: componentWithSuspenseAndErrorBoundary(Assets),
        children: [
          {
            path: RoutePaths.Details,
            element: componentWithSuspenseAndErrorBoundary(AssetDetails),
            children: [
              {
                path: RoutePaths.AssetDetailProductInfomation,
                element: componentWithSuspenseAndErrorBoundary(AssetDetailsProductInfo),
              },
              {
                path: RoutePaths.AssetDetailContacts,
                element: componentWithSuspenseAndErrorBoundary(AssetDetailsContact),
              },
              {
                path: RoutePaths.AssetDetailServiceContract,
                element: componentWithSuspenseAndErrorBoundary(AssetDetailsServiceContract),
              }
            ]
          },
        ]
      },
      {
        path: RoutePaths.Companies,
        element: componentWithSuspenseAndErrorBoundary(Companies),
        children: [
          {
            path: RoutePaths.Details,
            element: componentWithSuspenseAndErrorBoundary(CompaniesDetails),
          }
        ]
      },
      {
        path: RoutePaths.AssetsMap,
        element: componentWithSuspenseAndErrorBoundary(AssetsMap),
      },
      {
        path: RoutePaths.UserInfo,
        element: componentWithSuspenseAndErrorBoundary(TokenInfo),
      },
      {
        path: RoutePaths.Faq,
        element: componentWithSuspenseAndErrorBoundary(Faq),
      }
    ],
  },

  {
    path: RoutePaths.UserLogin,
    element: componentWithSuspenseAndErrorBoundary(Login, <PageLoader />),
    children: [
      {
        path: UserRoutes.UserLoginCallback,
        element: <Callback />,
      },
    ],
  },
  {
    path: RoutePaths.NotFound,
    element: <NotFound />,
  },
];

const getBrowserRouter = () => createBrowserRouter(getRoutes());

export { getBrowserRouter, getRoutes };
